import React from 'react'
import { graphql } from 'gatsby'
import { Layout, SmallerContainer, SEO, Post } from 'components/common'
import './highlight.css'

export default props => {
  console.log('posts.js', props)

  const {
    data: { wordpressPost },
    pageContext: { next, prev },
  } = props
  return (
    <Layout>
      <SmallerContainer>
        <SEO
          type="NewsArticle"
          title={wordpressPost.title}
          articleBody={wordpressPost.description}
          datePublished={wordpressPost.date}
          dateModified={
            wordpressPost.dateModified
              ? wordpressPost.dateModified
              : wordpressPost.date
          }
          cover={
            wordpressPost.featured_media &&
            wordpressPost.featured_media.localFile &&
            wordpressPost.featured_media.localFile.childImageSharp &&
            wordpressPost.featured_media.localFile.childImageSharp.fluid
              ? wordpressPost.featured_media.localFile.childImageSharp.fluid
                  .srcWebp
              : null
          }
          location={'/' + wordpressPost.slug}
          description={wordpressPost.excerpt.replace(/<(.|\n)*?>/g, '')}
          readTime={wordpressPost.timeToRead}
        />
        <Post {...wordpressPost} next={next} prev={prev} />
      </SmallerContainer>
    </Layout>
  )
}

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      slug
      id
      excerpt
      date(formatString: "MMMM DD, YYYY")
      modified(formatString: "MMMM DD, YYYY")
      categories {
        id
        name
        slug
      }
      fields {
        readingTime {
          text
          minutes
          time
          words
        }
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 700) {
              src
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
